:root {
  /* font-size: 90%; */
  font-family: "Open Sans", sans-serif, helvetica;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100vh;
}

body,
input,
button,
textarea {
  font-weight: 500;
  font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

div,
input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent !important;
}


@media (min-width: 700px) {
  :root {
    /* font-size: 62.5%; */
  }
}



/* ScrollBar Global Pages */

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: #292d38;
}



/* ScrollBar Global Pages */

.MuiDrawer-paper {
  background-color: #000000 !important;
}
