.country-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background: darkgreen;
  font-family: 'Source Sans Pro', sans-serif;
}

.country-page-title {
  color: white;
  font-size: 32px;
  line-height: 1.125;
  font-weight: 600;
  letter-spacing: .004em;
  text-align: center;
}

.country-header-content {
  padding: 25px 20%;
  text-align: center;
}

.country-privalita-title {
  color: white;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: bolder;
  text-decoration: none;
}

.txtRed {
  color: rgb(255, 0, 0);
}

.country-privalita-subtitle {
  margin: 5px 0 0;
  font-size: 16px;
  color: #ccc;
}

.separator {
  width: 85%;
  border: 1.5px solid red;
}

.separator:empty {
  border-bottom: none !important;
}

.domains-addressbar {
  position: relative;
  display: flex;
  align-items: center;
  padding: 25px 10px;
  background-color: white;
  border-radius: 6px;
}

.wordRotateContainer {
  display: inline-block;
  height: 1.3em;
  line-height: 1.35em;
  vertical-align: text-bottom;
  overflow: hidden !important;
}

.innerWordRotate {
  position: relative;
  display: block;
  animation: word-rotate 30s infinite linear;
  -webkit-animation: word-rotate 30s infinite linear;
}

.innerWordRotate span {
  display: block;
  text-align: left;
}

@-webkit-keyframes word-rotate {
  0% {
    top: 0px;
  }
  100% {
    top: -730px;
  }
}

@keyframes word-rotate {
  0% {
    top: 0px;
  }
  100% {
    top: -730px;
  }
}

.domains-adressbar-content {
  position: absolute;
  left: 60px;
  color: black;
  font-size: 26px;
}

.country-world-links {
  position: relative;
  margin: 0 5%;
  border: 1px solid #010101;
  border-radius: 6px;
  z-index: 2;
}

.country-world-links .country-linkExtensions {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(16, 16, 43);
  object-fit: cover;
  z-index: -1;
}

.world-extensions-content {
  padding: 15px;
}

.country-item-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: .3em 1%;
  float: left;
  width: 23%;
  height: 80px;
}

.country-location-box {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 10px;
  color: #aaa;
  font-size: 20px;
  text-transform: capitalize;
  text-decoration: none;
}

.country-locations-title {
  color: white;
  font-weight: bold;
  margin: 0 0 0.5em;
}

.country-location-link {
  margin: 0;
}

.country-locations-domain {
  margin-left: 4px;
  color: rgb(255, 0, 0);
  text-transform: capitalize;
  padding: 5px 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 80, 80, 0.3);
  border-radius: 4px;
}